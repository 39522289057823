
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

export const GET_STYLE_TAG = "get_store_front_style_tag"

const BASE_STORE_FRONT_URL = BASE_API_URL+"store_fronts"
const BASE_APP_URL = BASE_API_URL+"app"

export enum StoreFrontMixinTags {
  Show = 'StoreFrontShow'
}

@Component
export default class StoreFrontsMixin extends Vue {

  getStoreFronts(data: any, tag: string) {
    return this.$httpGetQuery(BASE_STORE_FRONT_URL, tag,data);
  }

  getStoreFront(id: number | string, tag: string) {
    return this.$httpGet( BASE_STORE_FRONT_URL+"/"+id, tag);
  }

  destroyStoreFront(id: string|number, tag: string) {
    return this.$httpDelete(BASE_STORE_FRONT_URL+"/"+id, tag);
  }

  createStoreFront(data: any, tag: string) {
    return this.$httpPost(BASE_STORE_FRONT_URL, tag, data);
  }

  updateStoreFront(data: any, tag: string) {
    return this.$httpPut(BASE_STORE_FRONT_URL+"/"+data.id, tag, data);
  }

  getStyle(id: any, tag: string) {
    const url = BASE_APP_URL+"/store_fronts/"+id+"/style";
    return this.$httpGet(url, tag);
  }

  confirmWithPassword(data: any, tag: string) {
    const url = BASE_APP_URL+"/auth/confirm_with_password";
    return this.$httpPost(url,tag,data);
  }
  resetPassword(data: any, tag: string) {
    const url = BASE_APP_URL+"/auth/reset_password";
    return this.$httpPost(url,tag,data);
  }
}
