
















































import { Component, Mixins } from 'vue-property-decorator';
import StoreFrontMixin from '@/mixins/http/StoreFrontsMixin';
import DisplayErrors from '@/components/DisplayErrors.vue';
import { RequestData } from '@/store/types/RequestStore';
import { RegisterHttp } from '@/utils/Decorators';
import { GET_STYLE_TAG } from '@/mixins/http/StoreFrontsMixin';

@Component({ components: { DisplayErrors } })
export default class Confirmation extends Mixins(StoreFrontMixin) {
  private isValid = false;
  private password = '';
  private repeatPassword = '';
  private style: any = {};
  private logo: string;

  @RegisterHttp('get_store_front_style_tag') http: RequestData;
  @RegisterHttp('reset_password') resetRequest: RequestData;

  get token() {
    return this.$route.query.token as string;
  }

get storeFrontUuid() {
    return this.$route.query.store_front as string;
  }

  async created() {
    this.style = await this.getStyle(this.uuid, GET_STYLE_TAG);
  }

  get containerStyle() {
    return { backgroundColor: this.style?.backgroundColor};
  }

  get titleStyle() {
    return {color: this.style?.titleColor}
  }

  get buttonStyle() {
    return {color: this.style?.buttonTextColor, backgroundColor: this.style?.buttonBackgroundColor, fontWeight: 'bold', borderRadius: `${this.style.buttonBorderRadius}px`};
  }

  get inputStyle() {
    return {color: this.style?.inputTextColor, backgroundColor: this.style?.inputBackgroundColor, borderRadius: `${this.style.inputBorderRadius}px`};
  }

  get spinnerStyle() {
    return {color: this.style?.spinnerColor+" !important", zIndex: 1000}
  }

  get uuid(){
    return this.$route.query.store_front;
  }

  async submit() {
    const data = {
      token: this.token,password: this.password, storeFrontUuid: this.storeFrontUuid
    }
   await this.resetPassword(data, 'reset_password');
  }


}
